import axios from 'axios';
import { apiBaseUrl } from '../config/index'
import Notification from "../components/commonComponents/notification";
import userManager from '../utils/userManage'

const errorMsg = {
    '401': 'Unauthorized',
    '403': 'Operation denied',
    '500': 'Server error',
    '429': 'Too many requests, try again later!'
}

const handleError = (status, { catchError }) => {
    if (window.location.href.includes('/Account/Login') || window.location.href.includes('/Account/Logout')) {
        return
    }
    if (!catchError && Number(status) !== 404) {
        Notification.error({
            message: 'error',
            description: errorMsg[status] || 'An unknown error!'
        })
    }

    if (status === 401) {
        setTimeout(() => {
            window.location.search = window.location.href
            window.location = '/Account/Login'
            localStorage.clear()
        }, 3000)
    }

    if (status === 403) {
        setTimeout(() => {
            // token expired
            localStorage.clear()
            window.location.search = window.location.href
            window.location = '/Account/Login'
        }, 3000)
    }
}

// get token
const AUTH = 'Authorization'

//request list
const requestList = [];

//cancel request list
const CancelToken = axios.CancelToken;
let sources = {};

//global setting
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// request interceptors
axios.interceptors.request.use((config) => {
    const request = JSON.stringify(config.url) + JSON.stringify(config.data);

    config.cancelToken = new CancelToken((cancel) => {
        sources[request] = cancel
    })
    // Determine whether the request already exists in the request list, avoid repeating the request, and add the current request to the request list array;
    if (requestList.includes(request)) {
        sources[request]('cancel repeat request')
    } else {
        requestList.push(request)
    }

    return config;
}, (error) => {
    return Promise.reject(error);
})

axios.interceptors.response.use((response) => {
    const request = JSON.stringify(response.config.url) + JSON.stringify(response.config.data);
    requestList.splice(requestList.findIndex(item => item === request), 1);
    return response
}, (error) => {
    if (axios.isCancel(error)) {
        throw new axios.Cancel('cancel request');
    } else {
        const response = error.response;
        const request = JSON.stringify(response.config.url) + JSON.stringify(response.config.data);
        requestList.splice(requestList.findIndex(item => item === request), 1);
    }
    return Promise.reject(error);
})

const request = (url, method, params, settings) => userManager.getUser().then(user => {
    if (user) {
        //Get the token and add it to the request header for the backend to check the permissions
        axios.defaults.headers[AUTH] = 'Bearer ' + user.access_token;
    }
    return apiBaseUrl.then((baseURL) => {
        axios.defaults.baseURL = baseURL;
        return axios[method](url, params, Object.assign({}, settings)).catch(error => {
            // if you need to catch error, set settings value with '{catchError:true}'
            handleError(error && error.response && error.response.status, settings);
            return Promise.reject(error)
        });
    })
});


const apiService = {
    post: (url, params, settings = {}) => {
        return request(url, 'post', params, settings);
    },

    get: (url, params, settings = {}) => {
        return request(url, 'get', params, settings);
    },

    delete: (url, params, settings = {}) => {
        return request(url, 'delete', params, settings);
    },

    put: (url, params, settings = {}) => {
        return request(url, 'put', params, settings);
    }
}


export default apiService;


